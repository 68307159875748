import { message } from "antd";
import { getToken, messaging } from "../firebase/firebase";
import { apiUrls } from "../hooks/apiUrls";
import { request } from "./axios.service";

export const requestPermission = async () => {
  try {
    const permission = await Notification.requestPermission();

    if (permission === "granted") {
      const fcmToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });

      console.log("fcmToken", fcmToken);

      if (fcmToken) {
        await request({
          url: apiUrls.auth.GENERATE_FCM_TOKEN,
          method: "POST",
          data: { token: fcmToken },
        });
      }
    } else {
      message.error("Please enable notification for latest requests");
    }
  } catch (error) {
    console.log("error", error);
  }
};
