import PageContainer from "../../../ui/page-container/PageContainer";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Row,
  Select,
  Space,
  Typography,
} from "antd";
import { yupValidator } from "../../../utils/yupSync";
import { forms } from "../../../forms";
import { useUpdateConstantMutation } from "../../../hooks/settings/mutation/useUpdateConstant.mutation";
import { useSettingsQuery } from "../../../hooks/settings/query/useSettings.query";
import { useMemo } from "react";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";

interface IFieldType {
  currencyOfferRate: TSettingsModel["currencyOfferRate"];
  minimumTransactionLimits: TSettingsModel["minimumTransactionLimits"];
  blockchainNetworkTypes: TSettingsModel["blockchainNetworkTypes"];
}

const AppConstants = () => {
  const [form] = Form.useForm<IFieldType>();
  const yupSync = yupValidator(
    forms.appConstants.validation,
    form.getFieldsValue
  );

  const { isLoading, data } = useSettingsQuery();

  const settings: TSettingsModel = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return undefined;
    }
  }, [isLoading, data]);

  const { isPending, mutateAsync } = useUpdateConstantMutation();

  const onFinish = async (values: IFieldType) => {
    const res = await mutateAsync(values);
    if (res.status === "success") {
      message.success(res.message);
    } else {
      message.error(res.data.message);
    }
  };

  return (
    <PageContainer loading={isLoading}>
      <Typography.Title level={4}>App Constants</Typography.Title>
      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        name="blockchain account"
        size="middle"
        form={form}
        onFinish={onFinish}
        initialValues={{
          ...forms.blockchainAccount.values,
          currencyOfferRate: settings?.currencyOfferRate,
          minimumTransactionLimits: settings?.minimumTransactionLimits,
          blockchainNetworkTypes:
            settings?.blockchainNetworkTypes.length > 0
              ? settings?.blockchainNetworkTypes
              : forms.appConstants.values.blockchainNetworkTypes,
        }}
      >
        <Row gutter={10}>
          <Col span={24}>
            <Typography.Title level={5}>Offer Rate in (%)</Typography.Title>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["currencyOfferRate", "BTC"]}
              rules={[yupSync]}
              label="BTC (%)"
            >
              <Input placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["currencyOfferRate", "BNB"]}
              rules={[yupSync]}
              label="BNB (%)"
            >
              <Input placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["currencyOfferRate", "ETH"]}
              rules={[yupSync]}
              label="ETH (%)"
            >
              <Input placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["currencyOfferRate", "SOL"]}
              rules={[yupSync]}
              label="SOL (%)"
            >
              <Input placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["currencyOfferRate", "TRX"]}
              rules={[yupSync]}
              label="TRX (%)"
            >
              <Input placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Item<IFieldType>
              name={["currencyOfferRate", "TONCOIN"]}
              rules={[yupSync]}
              label="TONCOIN (%)"
            >
              <Input placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row gutter={20}>
          <Col xs={24}>
            <Typography.Title level={5}>
              Minimum Transaction limit
            </Typography.Title>
          </Col>
          <Col xs={24} xl={12} span={12}>
            <Form.Item<IFieldType>
              name={["minimumTransactionLimits", "usdtDeposit"]}
              rules={[yupSync]}
              label="USDT Deposit"
            >
              <Input type="number" placeholder="Enter offer percentage" />
            </Form.Item>
          </Col>
        </Row>

        <Divider />
        <Row gutter={10}>
          <Col xs={24} style={{ marginBottom: 10 }}>
            <Typography.Title level={5}>
              Blockchain Network Types
            </Typography.Title>
          </Col>
          <Col span={24}>
            <Form.List name="blockchainNetworkTypes">
              {(fields, { add, remove }) => (
                <>
                  {fields.map(({ key, name, ...restField }, index) => (
                    <Space
                      key={key}
                      style={{ display: "flex", marginBottom: 8 }}
                      align="baseline"
                    >
                      <Form.Item<IFieldType["blockchainNetworkTypes"]>
                        {...restField}
                        name={[name, "label"]}
                        rules={[
                          {
                            required: true,
                            message: "Network type is required",
                          },
                        ]}
                      >
                        <Input placeholder="Network Type" />
                      </Form.Item>
                      <Form.Item
                        {...restField}
                        name={[name, "type"]}
                        rules={[
                          { required: true, message: "Select currency type" },
                        ]}
                      >
                        <Select
                          placeholder="Select Currency"
                          style={{ minWidth: 100 }}
                        >
                          <Select.Option value="BTC">BTC</Select.Option>
                          <Select.Option value="BNB">BNB</Select.Option>
                          <Select.Option value="SOL">SOL</Select.Option>
                          <Select.Option value="ETH">ETH</Select.Option>
                          <Select.Option value="TRX">TRX</Select.Option>
                          <Select.Option value="TONCOIN">TONCOIN</Select.Option>
                        </Select>
                      </Form.Item>
                      {fields.length > 1 && (
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      )}
                      {fields.length === index + 1 && (
                        <PlusCircleOutlined onClick={() => add()} />
                      )}
                    </Space>
                  ))}
                </>
              )}
            </Form.List>
          </Col>
        </Row>

        <Form.Item>
          <Button
            loading={isPending}
            size="large"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </PageContainer>
  );
};

export default AppConstants;
