import { createBrowserRouter } from "react-router-dom";
import ErrorPage from "../pages/error/ErrorPage";
import LoginPage from "../pages/login/LoginPage";
import ProtectedRoute from "../routers/Protected.route";
import Dashboard from "../pages/dashboard/Dashboard";
import PaymentMethods from "../pages/settings/payment-methods/PaymentMethods";
import AppConstants from "../pages/settings/constants/AppConstants";
import SocialLinks from "../pages/settings/social-links/SocialLinks";
import DepositRequests from "../pages/deposit-requests/DepositRequests";
import BuyTokenRequests from "../pages/buy-token-requests/BuyTokenRequests";
import AppInformation from "../pages/settings/app-information/AppInformation";
import Users from "../pages/users/Users";
import PopoverPriceRange from "../pages/settings/popover-price-range/PopoverPriceRange";

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    errorElement: <ErrorPage />,
    element: <ProtectedRoute />,
    children: [
      {
        path: "/",
        element: <Dashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/users",
        element: <Users />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/deposit-requests",
        element: <DepositRequests />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/buy-token-requests",
        element: <BuyTokenRequests />,
        errorElement: <ErrorPage />,
      },
      {
        path: "/settings",
        errorElement: <ErrorPage />,
        children: [
          {
            path: "app-information",
            element: <AppInformation />,
            errorElement: <ErrorPage />,
          },
          {
            path: "payment-methods",
            element: <PaymentMethods />,
            errorElement: <ErrorPage />,
          },
          {
            path: "app-constants",
            element: <AppConstants />,
            errorElement: <ErrorPage />,
          },
          {
            path: "social-links",
            element: <SocialLinks />,
            errorElement: <ErrorPage />,
          },
          {
            path: "popover-price-range",
            element: <PopoverPriceRange />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
  { path: "/login", errorElement: <ErrorPage />, element: <LoginPage /> },
]);
