import { LogoutOutlined } from "@ant-design/icons";
import { Button, Flex, Layout, Tooltip } from "antd";
import React from "react";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";

const headerStyle: React.CSSProperties = {
  position: "sticky",
  top: 0,
  zIndex: 1,
  width: "100%",
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  height: 50,
  paddingRight: 22,
};

const TheHeader = () => {
  const signOut = useSignOut();
  const navigate = useNavigate();
  return (
    <Layout.Header style={headerStyle}>
      <Flex justify="flex-end" flex={1}>
        <Tooltip title="Logout">
          <Button
            onClick={() => {
              signOut();
              localStorage.clear();
              navigate("/login");
            }}
            type="primary"
            shape="default"
            icon={<LogoutOutlined />}
          />
        </Tooltip>
      </Flex>
    </Layout.Header>
  );
};

export default TheHeader;
