import { useQuery } from "@tanstack/react-query";
import { request } from "../../../services/axios.service";
import { apiUrls } from "../../apiUrls";

const get = async (params: TQueryParams & { dashboard?: boolean }) => {
  const response: TServerResponse = await request({
    url: apiUrls.depositRequests.FETCH_REQUESTS,
    method: "GET",
    params: {
      ...params.paging,
      search: params.searchParams?.search,
      status: params.status,
      dashboard: params.dashboard,
    },
  });
  return response;
};

export const useDepositRequestsQuery = (
  params: TQueryParams & { dashboard?: boolean }
) => {
  return useQuery({
    queryKey: ["admin", apiUrls.depositRequests.FETCH_REQUESTS, params],
    queryFn: () => get(params),
  });
};
