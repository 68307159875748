import { TableColumnsType, Typography } from "antd";
import moment from "moment";
import StatusMenu from "../pages/deposit-requests/components/status-menu/StatusMenu";

export const depositRequestsColumns: TableColumnsType<TDepositRequest> = [
  {
    title: "Username",
    key: "username",
    dataIndex: "username",
    render: (value) => (
      <Typography.Paragraph style={{ margin: 0 }} copyable>
        {value}
      </Typography.Paragraph>
    ),
  },
  {
    title: "Sender Address",
    key: "senderAddress",
    dataIndex: "senderAddress",
    render: (value, record) => (
      <Typography.Paragraph style={{ margin: 0 }} copyable>
        {record.paymentResponse?.senderAddress}
      </Typography.Paragraph>
    ),
  },
  {
    title: "Track Id",
    key: "trackId",
    dataIndex: "trackId",
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    render: (value: number) =>
      `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} USDT`,
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    render: (_value, record) => <StatusMenu data={record} />,
  },

  {
    title: "Created At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value) => moment(value).format("lll"),
  },
];
