import * as Yup from "yup";

export const appInfoValidation = Yup.object().shape({
  appInformation: Yup.object({
    discountedToken: Yup.object({
      title: Yup.string().required("Title field is required"),
      message: Yup.string().required("Description field is required"),
    }),
  }),
});
