import { RouterProvider } from "react-router-dom";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { AuthProvider } from "react-auth-kit";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ConfigProvider } from "antd";
import { useEffect } from "react";
import { onMessage } from "firebase/messaging";
import { messaging } from "./firebase/firebase";
import { appRoutes } from "./routes/appRoutes";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useEffect(() => {
    onMessage(messaging, (payload) => {
      console.log("payeload", payload);

      new Notification(payload.notification?.title ?? "Title", {
        body: payload.notification?.body,
      });
    });
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Segmented: {
            itemSelectedBg: "#228be6",
            itemSelectedColor: "white",
          },
        },
      }}
    >
      <AuthProvider
        authType={"localstorage"}
        authName={`${process.env.REACT_APP_AUTH_NAME}`}
        cookieDomain={window.location.hostname}
        cookieSecure={window.location.protocol === "https:"}
      >
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={appRoutes} />
          <ReactQueryDevtools
            position="bottom"
            buttonPosition="bottom-right"
            initialIsOpen={false}
          />
        </QueryClientProvider>
      </AuthProvider>
    </ConfigProvider>
  );
}

export default App;
