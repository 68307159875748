import { useMemo, useState } from "react";
import PageContainer from "../../ui/page-container/PageContainer";
import { Button, Flex, Input, Table, Tooltip, Typography } from "antd";

import { columns } from "../../columns";
import { useUsersQuery } from "../../hooks/users/query/useUsers.query";
import { WalletTwoTone } from "@ant-design/icons";
import WalletModal from "./components/WalletModal";

import ActionButton from "./components/ActionButton";

const Users = () => {
  const [search, setSearch] = useState("");
  const [wallet, setWallet] = useState<{
    opened: boolean;
    user: TUser | undefined;
  }>({ opened: false, user: undefined });

  const { data, isLoading, isFetching, refetch } = useUsersQuery({
    searchParams: {
      search: search,
    },
  });

  const tableColumns = [...columns.USERS];

  const dataSource: TUser[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data ?? [];
    } else {
      return [];
    }
  }, [isLoading, data]);

  tableColumns.push({
    key: "walletAction",
    title: "Wallet Action",
    dataIndex: "usdtWallet",
    render: (_, row) => (
      <Tooltip title="Update wallet">
        <Button
          onClick={() => setWallet({ opened: true, user: row })}
          size="small"
          type="text"
          shape="default"
          icon={<WalletTwoTone style={{ fontSize: 20 }} />}
        />
      </Tooltip>
    ),
    align: "center",
    width: 120,
  });
  tableColumns.push({
    key: "action",
    title: "Actions",
    dataIndex: "actions",
    render: (_, row) => (
      <ActionButton _id={row._id} refetch={refetch} status={row.deactivated} />
    ),
    align: "center",
    width: 120,
  });

  return (
    <PageContainer>
      <Flex align="center" justify="space-between" wrap>
        <Typography.Title level={4}>Users</Typography.Title>

        <Input.Search
          style={{ width: 250 }}
          placeholder="Search by username"
          onChange={(e) => setSearch(e.target.value)}
          loading={isFetching}
          value={search}
          allowClear
        />
      </Flex>
      <Table
        scroll={{ x: "max-content" }}
        style={{ marginTop: 20 }}
        size="middle"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={{ hideOnSinglePage: true }}
      />
      <WalletModal
        refetch={refetch}
        close={() => setWallet({ opened: false, user: undefined })}
        user={wallet.user}
        opened={wallet.opened}
      />
    </PageContainer>
  );
};

export default Users;
