import { Col, Flex, Row, Spin, Typography } from "antd";
import React, { FC, memo } from "react";

interface IPageContainer {
  loading?: boolean;
  children: React.ReactNode;
  emptyData?: boolean;
  emptyDataMessage?: string;
}

const PageContainer: FC<IPageContainer> = ({
  loading,
  children,
  emptyData,
  emptyDataMessage = "Data not found",
}) => {
  return (
    <Flex wrap="wrap" style={{ minHeight: "70vh" }} vertical>
      {!loading && emptyData && (
        <Row>
          <Col span={24}>
            <Flex style={{ minHeight: "80vh" }} justify="center" align="center">
              <Typography.Title level={4}> {emptyDataMessage}</Typography.Title>
            </Flex>
          </Col>
        </Row>
      )}
      {loading ? (
        <Flex style={{ minHeight: "80vh" }} justify="center" align="center">
          <Spin size="large"></Spin>
        </Flex>
      ) : (
        children
      )}
    </Flex>
  );
};

export default memo(PageContainer);
