import { Tag } from "antd";
import { FC, memo } from "react";
import { styles } from "./styles";
import { getStatusTagColors } from "../../../../utils/getStatusTagColors";

interface IStatusMenu {
  data: TPayouts;
}

const StatusMenu: FC<IStatusMenu> = ({ data: { status, _id } }) => {
  return (
    <Tag style={styles.menuStyle} color={getStatusTagColors(status)}>
      {status}
    </Tag>
  );
};

export default memo(StatusMenu);
