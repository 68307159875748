import { adminPrefix } from "../../constants";

export const settingsApi = {
  FETCH_SETTINGS: adminPrefix + "settings",

  // payment methods
  ADD_BLOCKCHAIN_ACCOUNT: adminPrefix + "settings/payment-method/create",
  UPDATE_BLOCKCHAIN_ACCOUNT: adminPrefix + "settings/payment-method/update",
  DELETE_BLOCKCHAIN_ACCOUNT: adminPrefix + "settings/payment-method/delete",
  UPDATE_DETAILS_CHANGE_TIME:
    adminPrefix + "settings/update-details-change-time",

  // constants
  UPDATE_CONSTANTS: adminPrefix + "settings/update-constants",
  UPDATE_SOCIAL_URLS: adminPrefix + "settings/update-social-urls",
  UPDATE_APP_INFO: adminPrefix + "settings/update-app-info",
  UPDATE_POPOVER_PRICE_RANGE:
    adminPrefix + "settings/update-popover-price-range",
};
