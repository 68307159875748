export const appConstantsIniValues = {
  currencyOfferRate: {
    BTC: "",
    ETH: "",
    BNB: "",
    SOL: "",
    TRX: "",
    TONCOIN: "",
  },
  minimumTransactionLimits: {
    usdtDeposit: "",
  },
  blockchainNetworkTypes: [
    {
      label: "",
      type: "",
    },
  ],
};
