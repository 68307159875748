import { useMemo } from "react";

import { Flex, Table, Typography } from "antd";
import { useBuyTokenRequestsQuery } from "../../../hooks/buy-token-requests/query/useBuyTokenRequests.query";
import { columns } from "../../../columns";
import PageContainer from "../../../ui/page-container/PageContainer";
import NewBuyRequest from "../../../socket-events/NewBuyRequest";

const LatestWithdraws = () => {
  const { data, isLoading, refetch } = useBuyTokenRequestsQuery({
    status: "Complete",
    dashboard: true,
  });

  const tableColumns = [...columns.BUY_REQUESTS];

  const dataSource: TPayouts[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data ?? [];
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <PageContainer loading={isLoading}>
      <Flex align="center" justify="space-between" wrap>
        <Typography.Title level={4}>Today's Buy Tokens</Typography.Title>
      </Flex>
      <Table
        scroll={{ x: "max-content" }}
        style={{ marginTop: 20 }}
        size="small"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={{ hideOnSinglePage: true }}
      />
      <NewBuyRequest callback={() => refetch()} />
    </PageContainer>
  );
};

export default LatestWithdraws;
