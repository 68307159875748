import { TableColumnsType, Typography } from "antd";
import moment from "moment";

export const userColumns: TableColumnsType<TUser> = [
  {
    title: "Username",
    key: "username",
    dataIndex: "username",
    render: (value) => (
      <Typography.Paragraph style={{ margin: 0 }} copyable>
        {value}
      </Typography.Paragraph>
    ),
  },
  {
    title: "USDT Balance",
    key: "amount",
    dataIndex: "usdtWallet",
    render: (value: TUser["usdtWallet"]) =>
      `${value.deposit.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} USDT`,
  },
  {
    title: "Created At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value) => moment(value).format("lll"),
    width: 200,
  },
];
