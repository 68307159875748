import { IconTrash, IconUser, IconUserOff } from "@tabler/icons-react";
import {
  Button,
  Flex,
  message,
  Modal,
  notification,
  Tooltip,
  Typography,
} from "antd";
import React, { FC, memo } from "react";
import { useUpdateAccountStatusMutation } from "../../../hooks/users/mutation/useUpdateAccountStatus.mutation";
import { useDeleteAccountMutation } from "../../../hooks/users/mutation/useDeleteAccount.mutation";

interface IActionButton {
  refetch: () => void;
  status: boolean;
  _id: string;
}

const ActionButton: FC<IActionButton> = ({ _id, refetch, status }) => {
  const { isPending, mutateAsync } = useUpdateAccountStatusMutation();
  const handleStatusChange = async (value: string) => {
    const res = await mutateAsync({ userId: _id, status: !status });
    if (res.status === "success") {
      refetch();
      message.success(res.message);
    } else {
      notification.error({ message: res.data?.message });
    }
  };
  const { isPending: deleteLoading, mutateAsync: deleteAccount } =
    useDeleteAccountMutation();

  const handleDelete = async (value: string) => {
    const res = await deleteAccount({ userId: _id });
    if (res.status === "success") {
      refetch();
      message.success(res.message);
    } else {
      notification.error({ message: res.data?.message });
    }
  };

  return (
    <Button.Group>
      <Tooltip title={status ? "Activate account" : "Deactivate account"}>
        <Button
          onClick={() =>
            Modal.confirm({
              title: "Confirmation !",
              centered: true,
              onOk: handleStatusChange,

              content: (
                <Flex>
                  Are you sure you want to
                  {!status ? (
                    <Typography.Text
                      style={{ margin: "0px 5px" }}
                      type="danger"
                      strong
                    >
                      Deactivate
                    </Typography.Text>
                  ) : (
                    <Typography.Text
                      style={{ margin: "0px 5px" }}
                      type="success"
                      strong
                    >
                      Activate
                    </Typography.Text>
                  )}
                  this account?
                </Flex>
              ),
              footer: (_, { OkBtn, CancelBtn }) => (
                <>
                  <CancelBtn />
                  <OkBtn />
                </>
              ),
            })
          }
          loading={isPending}
          size="small"
          type="text"
          shape="default"
          icon={
            status ? (
              <IconUserOff color="red" />
            ) : (
              <IconUser color="rgb(22, 119, 255)" />
            )
          }
        />
      </Tooltip>
      <Tooltip title={"Delete Account"}>
        <Button
          onClick={() =>
            Modal.confirm({
              title: "Confirmation !",
              centered: true,
              onOk: handleDelete,

              content: (
                <Flex>
                  Are you sure you want to
                  <Typography.Text
                    style={{ margin: "0px 5px" }}
                    type="danger"
                    strong
                  >
                    Delete
                  </Typography.Text>
                  this account?
                </Flex>
              ),
              footer: (_, { OkBtn, CancelBtn }) => (
                <>
                  <CancelBtn />
                  <OkBtn />
                </>
              ),
            })
          }
          loading={deleteLoading}
          size="small"
          type="text"
          shape="default"
          icon={<IconTrash color="red" />}
          style={{ marginLeft: 10 }}
        />
      </Tooltip>
    </Button.Group>
  );
};

export default memo(ActionButton);
