import { Avatar, Card, Col, Flex, Row, Typography } from "antd";
import PageContainer from "../../ui/page-container/PageContainer";

import {
  IconArrowAutofitDown,
  IconArrowAutofitUp,
  IconUsers,
} from "@tabler/icons-react";
import LatestDeposits from "./components/LatestDeposits";
import LatestWithdraws from "./components/LatestWithdraws";
import { useDashboardCardsQuery } from "../../hooks/dashboard/query/useDashboardCards.query";
import { useMemo } from "react";

const Dashboard = () => {
  const { isLoading, data } = useDashboardCardsQuery();

  const cards: {
    investedUsers: number;
    todaysDeposit: number;
    totalDeposit: number;
    totalWithdraw: number;
  } = useMemo(() => {
    if (!isLoading && data) {
      return data.data;
    } else {
      return {
        investedUsers: 0,
        todaysDeposit: 0,
        totalDeposit: 0,
        totalWithdraw: 0,
      };
    }
  }, [isLoading, data]);
  return (
    <PageContainer>
      <Typography.Title level={4}>Dashboard</Typography.Title>

      <Row
        style={{ marginBottom: "2em" }}
        gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
      >
        <Col className="gutter-row" span={6}>
          <Card bordered loading={isLoading}>
            <Flex justify="space-between" align="center">
              <Typography.Title level={5}>Invested Users</Typography.Title>
              <Avatar
                size={55}
                style={{ backgroundColor: "#69b1ff" }}
                icon={<IconUsers />}
              />
            </Flex>
            <Typography.Title level={3}>{cards.investedUsers}</Typography.Title>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card bordered loading={isLoading}>
            <Flex justify="space-between" align="center">
              <Typography.Title level={5}>Todays Deposit</Typography.Title>
              <Avatar
                size={55}
                style={{ backgroundColor: "#5cdbd3" }}
                icon={<IconArrowAutofitDown />}
              />
            </Flex>
            <Typography.Title level={3}>{cards.todaysDeposit}</Typography.Title>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card bordered loading={isLoading}>
            <Flex justify="space-between" align="center">
              <Typography.Title level={5}>Total Deposits</Typography.Title>
              <Avatar
                size={55}
                style={{ backgroundColor: "#b37feb" }}
                icon={<IconArrowAutofitDown />}
              />
            </Flex>
            <Typography.Title level={3}>{cards.totalDeposit}</Typography.Title>
          </Card>
        </Col>
        <Col className="gutter-row" span={6}>
          <Card bordered loading={isLoading}>
            <Flex justify="space-between" align="center">
              <Typography.Title level={5}>Total Withdraw</Typography.Title>
              <Avatar
                size={55}
                style={{ backgroundColor: "#95de64" }}
                icon={<IconArrowAutofitUp />}
              />
            </Flex>
            <Typography.Title level={3}>{cards.totalWithdraw}</Typography.Title>
          </Card>
        </Col>
      </Row>
      <Card style={{ marginBottom: "2em" }}>
        <LatestDeposits />
      </Card>
      <Card>
        <LatestWithdraws />
      </Card>
    </PageContainer>
  );
};

export default Dashboard;
