// src/firebase.ts
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBPpWa5bvsMVh3Y1qGi8NtVJ2AFVpHGlMQ",
  authDomain: "discounted-alts.firebaseapp.com",
  projectId: "discounted-alts",
  storageBucket: "discounted-alts.appspot.com",
  messagingSenderId: "160969329954",
  appId: "1:160969329954:web:09563bd0ac5ac670f4e97b",
  measurementId: "G-SYJ069XTMV",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);

export { messaging, getToken, onMessage };
