import { useMutation } from "@tanstack/react-query";
import { apiUrls } from "../../apiUrls";
import { request } from "../../../services/axios.service";

const update = async (data: {
  appInformation: TSettingsModel["appInformation"];
}) => {
  const response: TServerResponse = await request({
    url: apiUrls.settings.UPDATE_APP_INFO,
    method: "POST",
    data: data,
  });
  return response;
};

export const useUpdateAppInfoMutation = () => {
  return useMutation({ mutationFn: update });
};
