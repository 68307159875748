import React, { useEffect, useState } from "react";
import { Flex, Layout, Menu, theme, Typography } from "antd";

import TheHeader from "./TheHeader";
import { Outlet, useNavigate, useLocation } from "react-router-dom";

import { navLinks } from "./Navs";
import { requestPermission } from "../services/notification.service";
import { useCheckAuthenticated } from "../hooks/auth/useCheckAuthenthicated";
import { useIsAuthenticated } from "react-auth-kit";
import NewBuyRequest from "../socket-events/NewBuyRequest";
import NewDepositRequest from "../socket-events/NewDepositRequest";

const { Sider } = Layout;

const siderStyle: React.CSSProperties = {
  overflow: "auto",
  height: "100vh",
  position: "fixed",
  insetInlineStart: 0,
  top: 0,
  bottom: 0,
  scrollbarWidth: "thin",
  scrollbarColor: "unset",
};

const TheLayout: React.FC = () => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { refetch } = useCheckAuthenticated();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (isAuthenticated()) {
      refetch();
    }
  }, [isAuthenticated, refetch]);

  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <Layout hasSider>
      <Sider
        style={siderStyle}
        breakpoint="md"
        collapsedWidth="50"
        width={250}
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
      >
        {!collapsed && (
          <Flex align="center" justify="center" style={{ padding: 15 }}>
            <Typography.Title style={{ color: "white" }} level={4}>
              {process.env.REACT_APP_PROJECT_NAME}
            </Typography.Title>
          </Flex>
        )}

        <Menu
          theme="dark"
          mode="inline"
          defaultOpenKeys={["/" + pathname.split("/")[1]]}
          defaultSelectedKeys={[pathname]}
          items={navLinks(navigate)}
        />
      </Sider>
      <Layout style={{ marginInlineStart: collapsed ? 50 : 250 }}>
        <TheHeader />
        <Layout.Content
          style={{
            padding: 24,
          }}
        >
          <div
            style={{
              padding: 24,
              minHeight: "100vh",
              background: colorBgContainer,
              borderRadius: borderRadiusLG,
            }}
          >
            <Outlet />
          </div>
        </Layout.Content>
      </Layout>
      <NewBuyRequest />
      <NewDepositRequest />
    </Layout>
  );
};

export default TheLayout;
