export const getStatusTagColors = (
  status: TDepositRequest["status"] | TPayouts["status"]
) => {
  switch (status) {
    case "New":
      return "#228be6";
    case "Paid":
      return "#40c057";
    case "Complete":
      return "#40c057";
    case "Failed":
      return "#fa5252";

    default:
      return "#228be6";
  }
};
