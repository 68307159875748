import * as Yup from "yup";

export const popoverPriceRangeValidation = Yup.object().shape({
  popoverPriceRanges: Yup.object({
    BTC: Yup.object({
      start: Yup.string()
        .required("Start field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      end: Yup.string()
        .required("End field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
    ETH: Yup.object({
      start: Yup.string()
        .required("Start field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      end: Yup.string()
        .required("End field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
    BNB: Yup.object({
      start: Yup.string()
        .required("Start field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      end: Yup.string()
        .required("End field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
    SOL: Yup.object({
      start: Yup.string()
        .required("Start field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      end: Yup.string()
        .required("End field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
    TRX: Yup.object({
      start: Yup.string()
        .required("Start field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      end: Yup.string()
        .required("End field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
    TONCOIN: Yup.object({
      start: Yup.string()
        .required("Start field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
      end: Yup.string()
        .required("End field is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    }),
  }),
});
