import { Button, Form, Input, Modal, notification, Upload } from "antd";
import { FC, memo, useEffect } from "react";
import { forms } from "../../../../forms";
import { yupValidator } from "../../../../utils/yupSync";
import { UploadOutlined } from "@ant-design/icons";
import { useCreateAccountMutation } from "../../../../hooks/settings/mutation/useCreateAccount.mutation";
import { useUpdateAccountMutation } from "../../../../hooks/settings/mutation/useUpdateAccount.mutation";

interface IBlockchainAccountModal {
  isModalOpen: boolean;
  handleCancel: () => void;
  data?: TBlockchainAccount;
  type: "bep20" | "trc20";
  refetch: () => void;
}
type IFieldType = typeof forms.blockchainAccount.values;

const BlockchainAccountModal: FC<IBlockchainAccountModal> = ({
  data,
  handleCancel,
  isModalOpen,
  type,
  refetch,
}) => {
  const { isPending: addLoading, mutateAsync: createAccount } =
    useCreateAccountMutation();
  const { isPending: updateLoading, mutateAsync: updateAccount } =
    useUpdateAccountMutation();

  const [form] = Form.useForm<IFieldType>();
  const yupSync = yupValidator(
    forms.blockchainAccount.validation,
    form.getFieldsValue
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onFinish = async (values: any) => {
    const formData = new FormData();
    formData.append("blockchainNetwork", values.blockchainNetwork);
    formData.append("depositAddress", values.depositAddress);
    formData.append("type", type);

    if (values.qrCode.length > 0) {
      formData.append("qrCode", values.qrCode[0].originFileObj);
    }
    if (data) {
      formData.append("accountId", data._id);
    }

    const res = data
      ? await updateAccount(formData)
      : await createAccount(formData);

    if (res.status === "success") {
      refetch();
      form.resetFields();
      handleCancel();
      notification.success({
        message: res.message,
      });
    } else {
      notification.error({
        message: res.data.message,
      });
    }
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue({
        ...data,
        qrCode: [],
      });
    } else {
      form.setFieldsValue(forms.blockchainAccount.values);
    }
  }, [data, form]);

  return (
    <Modal
      centered
      title={data ? "Edit Account" : "Add Account"}
      open={isModalOpen}
      onCancel={handleCancel}
      footer={false}
    >
      <Form
        style={{ marginTop: 20 }}
        layout="vertical"
        name="blockchain account"
        size="large"
        form={form}
        onFinish={onFinish}
        initialValues={forms.blockchainAccount.values}
      >
        <Form.Item<IFieldType>
          name="blockchainNetwork"
          rules={[yupSync]}
          label="Blockchain address"
        >
          <Input placeholder="Enter blockchain address" />
        </Form.Item>

        <Form.Item<IFieldType>
          name="depositAddress"
          rules={[yupSync]}
          label="Deposit address"
        >
          <Input placeholder="Enter deposit address" />
        </Form.Item>

        <Form.Item
          name="qrCode"
          label="Upload QR code"
          valuePropName="fileList"
          getValueFromEvent={(e) => e && e.fileList}
          rules={[
            { required: data ? false : true, message: "Please upload a file!" },
          ]}
        >
          <Upload beforeUpload={() => false} maxCount={1}>
            <Button size="large" icon={<UploadOutlined />}>
              Click to Upload
            </Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button
            loading={addLoading || updateLoading}
            block
            size="large"
            type="primary"
            htmlType="submit"
          >
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default memo(BlockchainAccountModal);
