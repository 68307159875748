export const popoverPriceRangesValue = {
  popoverPriceRanges: {
    BTC: {
      start: 0,
      end: 0,
    },
    ETH: {
      start: 0,
      end: 0,
    },
    BNB: {
      start: 0,
      end: 0,
    },
    SOL: {
      start: 0,
      end: 0,
    },
    TRX: {
      start: 0,
      end: 0,
    },
    TONCOIN: {
      start: 0,
      end: 0,
    },
  },
};
