import { useMemo } from "react";

import { Flex, Table, Typography } from "antd";
import { useDepositRequestsQuery } from "../../../hooks/deposit-requests/query/useDepositRequests.query";
import { columns } from "../../../columns";
import PageContainer from "../../../ui/page-container/PageContainer";

const LatestDeposits = () => {
  const { data, isLoading } = useDepositRequestsQuery({
    status: "Paid",
    dashboard: true,
  });

  const tableColumns = [...columns.DEPOSIT_REQUESTS];

  const dataSource: TDepositRequest[] = useMemo(() => {
    if (!isLoading && data) {
      return data.data ?? [];
    } else {
      return [];
    }
  }, [isLoading, data]);

  return (
    <PageContainer loading={isLoading}>
      <Flex align="center" justify="space-between" wrap>
        <Typography.Title level={4}>Today's Deposit</Typography.Title>
      </Flex>
      <Table
        scroll={{ x: "max-content" }}
        style={{ marginTop: 20 }}
        size="small"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={{ hideOnSinglePage: true }}
      />
    </PageContainer>
  );
};

export default LatestDeposits;
