import { TableColumnsType, Typography } from "antd";
import moment from "moment";
import StatusMenu from "../pages/buy-token-requests/components/status-menu/StatusMenu";

export const buyRequestsColumns: TableColumnsType<TPayouts> = [
  {
    title: "Username",
    key: "username",
    dataIndex: "username",
    render: (value) => (
      <Typography.Paragraph style={{ margin: 0 }} copyable>
        {value}
      </Typography.Paragraph>
    ),
  },
  {
    title: "Amount",
    key: "amount",
    dataIndex: "amount",
    render: (value: number, record) =>
      `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} ${record.currency.toUpperCase()}`,
  },
  {
    title: "Amount (USDT)",
    key: "amountInUsdt",
    dataIndex: "amountInUsdt",
    render: (value: number) =>
      `${value.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })} USDT`,
  },

  {
    title: "Network Type",
    dataIndex: "network",
    key: "network",
  },
  {
    title: "Blockchain Address",
    dataIndex: "address",
    key: "address",
    render: (value) => (
      <Typography.Paragraph style={{ margin: 0 }} copyable>
        {value}
      </Typography.Paragraph>
    ),
  },
  {
    key: "status",
    dataIndex: "status",
    title: "Status",
    render: (_value, record) => <StatusMenu data={record} />,
    width: 200,
  },
  {
    title: "Created At",
    key: "createdAt",
    dataIndex: "createdAt",
    render: (value) => moment(value).format("lll"),
  },
];
