import * as Yup from "yup";

export const appConstantsValidation = Yup.object().shape({
  currencyOfferRate: Yup.object({
    BTC: Yup.string().required("BTC field is required"),
    ETH: Yup.string().required("ETH field is required"),
    BNB: Yup.string().required("BNB field is required"),
    SOL: Yup.string().required("SOL field is required"),
    TRX: Yup.string().required("TRX field is required"),
    TONCOIN: Yup.string().required("TONCOIN field is required"),
  }),
  minimumTransactionLimits: Yup.object({
    usdtDeposit: Yup.string().required("USDT field is required"),
  }),
});
